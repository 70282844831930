import React, {useEffect} from "react"

const Terms = () => {
    useEffect(() => {
        document.title = `LiteRails - Terms & Conditions`
      }, []);
    return (
        <>
        <div>
            <h2 className="privacy__title">Terms & Conditions</h2>
        </div>
        <div>
            <p className="privacy__text">Welcome to literails.com (the 'Site'). Here are some details about how we operate.
                Please review the following fundamental terms that govern your use of and purchases from our Site. Please be aware that your ongoing use of our Site indicates your consent to abide by and be bound by these terms.</p>

            <h3 className="privacy__subheading">Currency and Pricing</h3>

            <p className="privacy__text">All transactions are processed in the currency displayed on the product and checkout pages. In most countries, taxes are included in the price; if not, the tax will be shown on the checkout page before finalizing your purchase.</p>

            <h3 className="privacy__subheading">Shipping and Delivery</h3>

            <p className="privacy__text">Lite Rails currently offers shipping exclusively within Canada. For questions and inquiries regarding this, please contact us at literails93@gmail.com.
                We typically dispatch orders within 2 business days. Delivery times vary based on the selected service and destination. Standard post delivery ranges from 3 to 28 business days, depending on the shipping address.</p>

            <h3 className="privacy__subheading">Customer Service</h3>

            <p className="privacy__text">Lite Rails is dedicated to providing exceptional customer service and high-quality products. Our standard dispatch time frames fall within 1–3 business days. If you have any questions or concerns about a product, your order, or any other matter, please reach out to our support team at literails93@gmail.com.</p>

            <h3 className="privacy__subheading">Return Policy</h3>

            <p className="privacy__text">Lite Rails simplifies the return process to ensure your satisfaction with your purchase.
                All our products come with a 3-year warranty, so if anything isn't as expected, please contact us, and we'll promptly arrange an exchange or refund.
                If you've made a mistake in your purchase, don't hesitate to seek advice from our team. The sooner we address any issues, the better chance we have of reducing unnecessary shipping, which is more environmentally friendly. Even better, choose your items carefully before confirming your order.</p>

            <h3 className="privacy__subheading">Warranty</h3>

            <p className="privacy__text">Lite Rails products are covered by a 3-year warranty.</p>

            <h3 className="privacy__subheading">Copyright</h3>

            <p className="privacy__text">The content of our website, and the Site as a whole, is intended solely for personal, non-commercial use (except for merchandise purchases from our Site).
                You may download or copy the content and other downloadable materials displayed on literails.com for your personal use only. No right, title, or interest in any downloaded materials is transferred to you as a result of such downloading or copying.
                You may not reproduce (except as indicated above), publish, transmit, distribute, display, modify, create derivative works, sell, or participate in any sale, or exploit in any manner, in whole or in part, any of the content on this site.</p>
        </div>
        </>
    )
}
export default Terms;