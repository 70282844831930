

import './SingleProduct.scss'



const SingleProduct = ({name, price, image, description, productData}) => {

    const convertToDollar = ( cents ) => {
        if (isNaN(cents)|| cents < 0){
            return 'invalid input';
        }
        let dollar = cents/100;
            return dollar;
        
    }

    return (
        <>
        <div className="product__container">
            <div className="product__image-wrapper">
                <img className="product__image-image" src={image} alt="product" />
            </div>
            <div className='product__content-container'>
                <h2 className='product__content-title' >{name}</h2>
                <p className='product__content-description'>  Description: {description}</p>
                <h4 className='product__content-price'>Price: ${convertToDollar(price)} CAD</h4>
            </div>
        </div>
        </>
    )
}

export default SingleProduct;