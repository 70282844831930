import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';
import ScrollToTop from "./components/ScrollToTop.js";
import Header from "./components/Header/Header.js";
import Footer from "./components/Footer/Footer.js";
import HomePage from './pages/HomePage/HomePage.js';
import ShopPage from './pages/ShopPage/ShopPage.js';
import Terms from './pages/Terms/Terms.js';
import AboutPage from './pages/AboutPage/AboutPage.js';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.js';
import ProductIDPage from './pages/ProductIDPage/ProductIDPage.js';
import ShoppingCart from './pages/ShoppingCart/ShoppingCart.js';
import { useState } from 'react';
import { MyContext } from './Context.js';

function App() {
  const [shoppingCart, setShoppingCart] = useState([]);


  const handleAddItemToCart = (productData) => {
    setShoppingCart(prevShoppingCart => {
      const existingItemIndex = prevShoppingCart.findIndex(item => item.id === productData.id);
      if (existingItemIndex !== -1) {
        // If item already exists, update its quantity by creating a new array with the updated quantity
        const updatedShoppingCart = prevShoppingCart.map((item, index) => {
          if (index === existingItemIndex) {
            return { ...item, quantity: item.quantity + 1 }; // Increment quantity by 1
          } else {
            return item;
          }
        });
        return updatedShoppingCart;
      } else {
        // If item doesn't exist, add it to the cart with quantity 1
        const newShoppingCart = [...prevShoppingCart, { ...productData, quantity: 1 }];
        return newShoppingCart;
      }
    });
  }

  const handleDeleteProduct = (productToBeDeleted) => {
    const productIndex = shoppingCart.findIndex(item => item.id === productToBeDeleted.id);
      // If the product exists in the cart
      if (productIndex !== -1) {
        // Create a copy of the shopping cart array
        const updatedShoppingCart = [...shoppingCart];
  
        // Get the quantity of the product to be deleted
        const quantity = updatedShoppingCart[productIndex].quantity;
  
        // If the quantity is greater than 1, decrease the quantity by 1
        if (quantity > 1) {
          updatedShoppingCart[productIndex].quantity -= 1;
        } 
        // If the quantity is 1, remove the product from the cart
        else {
          updatedShoppingCart.splice(productIndex, 1);
        }
  
        // Return the updated shopping cart
        setShoppingCart(updatedShoppingCart);
      }
      // If the product doesn't exist in the cart, return the current cart unchanged
     
    
  }
  
  
  


  return (
    <>
    <MyContext.Provider value={{shoppingCart, handleAddItemToCart, handleDeleteProduct }}>
    <BrowserRouter>
    <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/shop" element={<ShopPage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path='/products/:productId' element={<ProductIDPage />}/>
        <Route path="/cart" element={<ShoppingCart />} />
      </Routes>
      <Footer />
    </BrowserRouter>
    </MyContext.Provider>
    </>
  );
}

export default App;
