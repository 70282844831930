import React, {useEffect} from "react";
import portrait from '../../assets/images/images/Craig.jpg';
import './AboutPage.scss';
import slappy from '../../assets/images/images/slappyCurb.png';


const AboutPage = () => {
    useEffect(() => {
        document.title = `LiteRails - About`
      }, []);
    return (
        <>
            <div className="about">
                <div className="about__wrapper">
                    <img className="about__image" src={portrait} alt= "Craig" />
                </div>
                <div className="content__wrapper">
                    <h2 className="content__title">About</h2>
                    <h4 className="content__text">LiteRails was established in 2020. They are a Canadian Company founded by Craig Monteith, Welder / Fabricator with over 10 years experience and Social Media Conglomerate; that supplies custom rails for skaters at home. Additionally, LiteRails is immersed in the skater community; creating and donating rails for skateparks, competitions and D.I.Y. spots.</h4>
                </div>
            </div>
            <div className="curb__wrapper">
                <img className="curb__image" src={slappy} alt="Slappy Curb" />
            </div>
        </>
    )
}

export default AboutPage;