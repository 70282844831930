import { useContext, useEffect } from "react";
import { MyContext } from "../../Context";
import './ShoppingCart.scss';
import trash from '../../assets/images/icons/trash.svg';
import { Link } from "react-router-dom";



const ShoppingCart = () => {
    useEffect(() => {
        document.title = `LiteRails - Shopping Cart`
        
      }, []);

    const {shoppingCart, handleDeleteProduct} = useContext(MyContext);

    const convertToDollar = ( cents ) => {
        if (isNaN(cents)|| cents < 0){
            return 'invalid input';
        }
        let dollar = cents/100;
            return dollar;
        
    }

  

    
    

   

    return (
        <>
        <div className="shopping__cart">
            <h1 className="shopping__cart-title">Welcome to your shopping cart!</h1>
            <ul className="shopping__cart-list">
            {shoppingCart.map((product) => {
                return (
                    <li key={product.id}>
                        <div className="product__wrapper">
                            <h3 className="product__name">{product.name}</h3>
                            <p className="product__price">${convertToDollar(product.priceInCents)*product.quantity} CAD</p>
                            <p className="product__quantity">Quantity: {product.quantity}</p>
                            <div onClick={()=>handleDeleteProduct(product)}>
                                <img src={trash} alt="trashcan"/>
                            </div>
                        </div>
                            
                    </li>
                )} )}
            </ul>
            <Link className="checkout__wrapper"  target="_blank" to='https://buy.stripe.com/5kA01k7U4d0E6s05kk'>
                <button className="checkout__button">Checkout</button>
            </Link>
        </div>
        </>
    )
}
export default ShoppingCart;