import './Header.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import blackLogo from '../../assets/images/logos/logo-black.png';
import blackCart from '../../assets/images/icons/black-cart.svg';
import menu from '../../assets/images/icons/menu-black.svg';
import MenuModal from '../MenuModal/MenuModal';
import { MyContext } from '../../Context';
import { useContext } from "react";

const Header = () => {

    const [open, setOpen ] = React.useState(false);
    const {shoppingCart} = useContext(MyContext);
     
    // const amountInCart = shoppingCart.length > 0 ? shoppingCart.length : 0;
    const amountInCart = shoppingCart.reduce((total, item) => total + item.quantity, 0);


    return (
        <>
        <header className='header'>
            <nav className='header__nav'>
                <Link to='/'>
                <img className="header__logo" src={blackLogo} alt="LiteRails Logo in White"/>
                </Link>
                <ul className='header__list'>
                    <Link to='/shop' className='header__list-item--wrapper'>
                        <li className='header__list-item'>Shop</li>
                    </Link>
                    <Link  to='/about' className='header__list-item--wrapper'>
                        <li className='header__list-item'>About</li>
                    </Link>
                    <Link to='/' className='header__list-item--wrapper'>
                        <li className='header__list-item'>Home</li>
                    </Link>
                    <Link to='/cart' className='header__list-item--wrapper'>
                        <li className='header__list-item'>
                            <img className='header__list-item--image' src={blackCart} alt="Shopping Cart Icon in Black"/>
                            <span>{amountInCart}</span>
                        </li>
                    </Link>
                </ul>
                <div className='header__hamburger-wrapper'>
                    <img className='header__hamburger' src={menu} alt="dropdown menu button for mobile view" onClick={() => setOpen(true)} />
                    <MenuModal open={open} onClose={()=> setOpen(false)} 
                    amountInCart={amountInCart}
                    
                    />
                </div>
            </nav>
        </header>
        </>
    )
} 
    export default Header;