import React, {useEffect, useState} from "react";
import axios from "axios";
import './ShopPage.scss';
import { Url } from "../../utils.js";
import ProductList from "../../components/ProductList/ProductList";



const ShopPage = () => {
    useEffect(() => {
        document.title = `LiteRails - Shop`
      }, []);
      const [dataList, setDataList] = useState([]);
      const [isFetching, setIsFetching] = useState(true);
   

      useEffect(()=> {
        const fetchProductData = async () => {
            try{
                const productDataResponse = await axios.get(`${Url}/api/products`);
                setDataList(productDataResponse.data);
                setIsFetching(false);
            } catch(error) {
                console.log(`Error: `, error);
            
            }
        }
        fetchProductData();
      }, [])

      if (isFetching) {
        return (
          <p>... Loading your product data ...</p>
        )
      }
     

    return (
        <>
            <ProductList dataList={dataList} 
            //  addToCart={addToCart}
             />
        </>
    )
}

export default ShopPage;
