import { Link } from 'react-router-dom';
import './Footer.scss';
import React from 'react';
import instagram from '../../assets/images/icons/instagram.svg'

const Footer = () => {
    return (
        <>
        <footer>
            <div className='footer'>
                <div className='footer__legals'>
                    <Link to='/terms'>
                        <p>Terms & Conditions</p>
                    </Link>
                    <Link to='/privacy'>
                        <p>Privacy Policy</p>
                    </Link>
                </div>
                <div className='footer__contact'>
                    <Link target="_blank" to='https://www.instagram.com/lite_rails/?hl=en'>
                    <img className='footer__contact-instagram' src={instagram} alt="instagram logo"/>
                    </Link>
                </div>
                <div className='footer__copy'>
                    <p>Developed with 💛 by <Link target="_blank" to="https://felicianemet.com/">Felicia_Codes</Link></p>
                </div>
            </div>
        </footer>
        </>
    )
} 
    export default Footer;