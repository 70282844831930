import React from "react";
import './HomePage.scss';
import bulb from '../../assets/images/images/litebulb.jpg';
import rainbow from '../../assets/images/images/rainbow.png';

const HomePage = () => {
    document.title = `LiteRails - Home`

    return (
        <>
            <div className="image__container">
                <img className="image__picture" src={bulb} alt="literails brand logo" />
            </div>
            <div className="brand__section">
                <div className="brand__section-slogan--container">
                    <h1 className="brand__section-slogan--title">Fabricated by Skaters for Skaters</h1>
                </div>
                <div className="brand__section-image--wrapper">
                    <img className="brand__section-image--picture" src={rainbow}  alt='Rainbow curb'/>
                </div>
            </div>
        </>
    )
}
export default HomePage;