import React from "react";
import ProductDetails from '../ProductDetails/ProductDetails.js';
import './ProductList.scss'
const ProductList = ({dataList}) => {

    const convertToDollar = ( cents ) => {
        if (isNaN(cents)|| cents < 0){
            return 'invalid input';
        }
        let dollar = cents/100;
            return dollar;
        
    }

    return (
        <>
            <ul className="product__List">
                {dataList.map((product) => {
                    return (<ProductDetails key={product.id} id={product.id} name={product.name} image={product.image} price={convertToDollar(product.priceInCents)}/>)
                })}
            </ul>
        </>
    )
}

export default ProductList;