import React from 'react';
import { Link } from 'react-router-dom';
import './ProductDetails.scss'


const ProductDetails = ({image, name, price, id, addToCart}) => {
    return (
        <>
        <li className="product__details-wrapper">
            <Link to={`/products/${id}`} className='product__details-wrapper--link'>
                <div className="product__details">
                    <img className="product__details-image" src={image} alt="product" />
                    <h4 className="product__details-name">{name}</h4>
                    <p className="product__details-price">Price: ${price} CAD</p>
                </div>
            </Link>
        </li>
        </>
    )
}

export default ProductDetails;
