import React from "react";
import { Link } from "react-router-dom";
import closeButton from '../../assets/images/icons/close-X.svg';
import cart from '../../assets/images/icons/black-cart.svg';
import './MenuModal.scss';


const MenuModal = ({open, onClose, amountInCart}) => {
    if(!open) return null;

    return (
        <>
        <div className="overlay">
            <div className="menu__modal">
                <div className="menu__modal-top">
                        <img className="menu__modal-close" src={closeButton} onClick={onClose} alt="close icon" />
                </div>
                <ul className="menu__modal-list">
                    <Link to='/shop'>
                        <li className="menu__modal-list--item" onClick={onClose}>Shop</li>
                    </Link>
                    <Link to='/about'>
                        <li className="menu__modal-list--item" onClick={onClose}>About</li>
                    </Link>
                    <Link to='/'>
                        <li className="menu__modal-list--item" onClick={onClose}>Home</li>
                    </Link>
                    <Link to='/cart'>
                        <li className="menu__modal-list--item" onClick={onClose}>
                            <img className="menu__modal-list--item-logo" src={cart} alt="cart" />
                            <span>{amountInCart}</span>
                        </li>
                    </Link>
                </ul>
            </div>
        </div>
        
        </>
    )
}

export default MenuModal;