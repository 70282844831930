import { useParams, Link } from "react-router-dom";
import { Url } from "../../utils";
import axios from "axios";
import React, {useState,useEffect} from "react";
import SingleProduct from '../../components/SingleProduct/SingleProduct.js';
import { useContext } from "react";
import { MyContext } from "../../Context.js";
import './ProductIDPage.scss';
import back from '../../assets/images/icons/back.svg';

const ProductIDPage = () => {
    useEffect(() => {
        document.title = `LiteRails - Product Details`
      }, []);

    const { productId } = useParams();
    const [productData, setProductData] = useState(null);
    const {handleAddItemToCart} = useContext(MyContext);

    useEffect(() => {
        const fetchDetailsData = async () => {
            try{   
                    const productDetailsDataResponse = await axios.get(`${Url}/api/products/${productId}`);
                    setProductData(productDetailsDataResponse.data);
            }catch(error) {
                console.log(`Error: `, error);
            }
        }
        fetchDetailsData();
      }, [productId]);

      if (!productData) {
        return (
          <p>... Loading product details ...</p>
        )
      }

      const onButtonClick = () => {
        handleAddItemToCart(productData);
        alert(`Successfully added item to your shopping cart!`)
      }

    return (
        <>
          <div className="parent__wrapper">
            <Link to="/shop">
              <img className="back__button" src={back} alt="back" />
            </Link>
            
            <SingleProduct name={productData.name} image={productData.image} description={productData.description} price={productData.priceInCents} />
            <button className="button" onClick={onButtonClick}>
                Add to Cart
            </button>
          </div>
            
            
        </>
    )
}

export default ProductIDPage;